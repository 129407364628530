export default {
  proxy_path: "https://2u8a3ozpuj.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://u8q91v8ld3.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyCYOofv7ePg35vhHG6jVcyRqHmVvUPXRLU",
  main_company_term_id: "2277",
  crm_base_url: "https://ccm.staging.newcrest.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.newcrest.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.staging.newcrest.forwoodsafety.com",
    APP_CLIENT_ID: "5aoq2gqbatcda1vcaf7ngsgbb6",
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://agq0joay73.execute-api.ap-southeast-2.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.staging.newcrest.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
